import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
  {
    id: 1,
    label: "MENUSIDEBAR.MENU_1",   //Home
    icon: "bx-home-circle",
    link: "/dashboard",
  },
  {
    id: 2,
    label: "MENUSIDEBAR.MENU_2",   //Recom
    icon: "bxs-calendar-check",
    link: "/recomendacion",
    // link:"/maintenance",
  },
  {
    id: 3,
    label: "MENUSIDEBAR.MENU_3",   //Jerarquía
    icon: "bxs-component",
   // link:"/maintenance",
    subItems: [
      {
        id: 31,
        label: "Event History",   //Event History
        icon: "mdi mdi-axis-arrow",
        //link:"/maintenance",
        subItems: [
          {
            id: 311,
            label: "ScoreCard",   //ScoreCard
            icon: "mdi mdi-axis-arrow",
            //link:"/event-history-scored-card",
            link:"/maintenance",
          },
          {
            id: 312,
            label: "Registro",   //registro
            icon: "mdi mdi-axis-arrow",
            link:"/event-history-registro",
            //link:"/maintenance",
          }
        ]
      },
      {
        id: 32,
        label: "Jerarquia",
        icon: "mdi mdi-axis-arrow",
        link: "/jerarquiaequipos",
        //link:"/maintenance",
      }
    ]
  },
  {
    id: 4,
    label: "MENUSIDEBAR.MENU_4",   //Gemelo Digital
    icon: "mdi mdi-axis-arrow",
    link:"/tarjetas3d",
    //link:"/maintenance",

  },
  {
    id: 5,
    label: "MENUSIDEBAR.MENU_5",   //Calidad del Dato
    icon:  "mdi mdi-database-import",  //"bx bx-target-lock",
    subItems: [
      {
        id: 210,
        label: "Dataloader",     // clasificar riesgo
        subItems: [

          {
            id: 501,
            label: 'MENUSIDEBAR.MENU_31',  //data loader claisficar riesgo
            link: '/dataloaderInspeccion',
            parentId: 210,
        },
        {
          id: 150,
          label: 'MENUSIDEBAR.MENU_32',  //data loader rondas
          link: '/dataloaderRecom',
          parentId: 210,
        },
        {
          id: 114,
          label: 'MENUSIDEBAR.MENU_33',  //data loader rondas
          link: '/dataloaderRondas',
          parentId: 210,
        },
        {
        id: 160,
        label: 'Iot',  //Data Iot
        link: '/dataloader-iot',
        parentId: 210
        },
        {
        id: 6,
        label: 'RBI',  //Data Loaders RBI
        link: '/dataloadersrbi',
        parentId: 210
        },
        ]
      },


      {
          id: 7,
          label: "MENUSIDEBAR.MENU_6",  //Conector SAP
          link:"/maintenance",
          parentId: 5
      },
      {
        id: 8,
        label: "MENUSIDEBAR.MENU_7",    //Conector AMS
        link:"/maintenance",
        parentId: 5
      }
    ]
  },
  {
    id: 9,
    label: "MENUSIDEBAR.MENU_8",   //Gestionar Salud
    icon: "mdi mdi-heart-pulse", //"bx bx-target-lock",
    subItems: [

      {
        id: 110,
        label: "MENUSIDEBAR.MENU_26",    //Rondas
        subItems: [

          {
              id: 111,
              label: 'MENUSIDEBAR.MENU_27',  // Crear Grupos Rondas
              link: '/gestionCrearEquipo',
              parentId: 110,
          },
          {
              id: 112,
              label: 'MENUSIDEBAR.MENU_28',  //Planificar Rondas
              link: '/gestionCrearRuta',
              parentId: 110,
          },
          {
              id: 115,
              label: 'MENUSIDEBAR.MENU_29',  //Aprobar Rondas
              link: '/RondasAprobar',
              parentId: 110,
          },
          {
            id: 116,
            label: 'MENUSIDEBAR.MENU_30',  //Registro Rondas
            link: '/ReviewRondas',
            parentId: 110,
            },
        ]
      },
      {
          id: 10,
          label: "MENUSIDEBAR.MENU_9",  //Inspecciones
          // link: '/intervecionequipos',
          // link:"/maintenance",
          subItems:[
            {
              id: 218,
              label: 'Scorecard',  // vista que no se usa
              link: '/intervecionequipos',
              parentId: 10,
            },
            {
              id: 219,
              label: "MENUSIDEBAR.MENU_38",  // Crear Grupos de inspeccion
              link: '/inspeccionequipo',
              parentId: 10,
            },
            {
              id: 220,
              label: "MENUSIDEBAR.MENU_39",  // planificar
              link: '/planificarinspeccion',
              parentId: 10,
            },
            {
              id: 221,
              label: "MENUSIDEBAR.MENU_40",  // aprobar  inspeccion
              link: '/aprobarinspeccion',
              parentId: 10,
            },
            {
              id: 222,
              label: "MENUSIDEBAR.MENU_41",  // registro de inspeccion
              link: '/registroinspeccion',
              parentId: 10,
            }

          ]
      },

      {
        id: 14,
        label: "IoT",  //Inspecciones
        // link: '/intervecionequipos',
        // link:"/maintenance",
        subItems:[

          {
            id: 240,
            label: "MENUSIDEBAR.MENU_38",  // Crear Grupos de IoT
            link: '/creariot',
            parentId: 14,
          },
          {
            id: 241,
            label: "MENUSIDEBAR.MENU_39",  // planificar IoT
            link: '/planificariot',
            parentId: 14,
          },
          {
            id: 242,
            label: "MENUSIDEBAR.MENU_40",  // aprobar IoT
            link: '/aprobariot',
            parentId: 14,
          }

        ]
    },
      {
        id: 11,
        label: "MENUSIDEBAR.MENU_10",    //Mapa Salud IoT
        link:"/maintenance",
        parentId: 9
      },
      {
        id: 12,
        label: "MENUSIDEBAR.MENU_11",    //Analítica Predictiva
        link:"/maintenance",
        parentId: 9
      }
    ]
  },
  {
    id: 13,
    label: "MENUSIDEBAR.MENU_12",   //Eliminar Defectos
    icon: "mdi mdi-bug-check-outline", //"bx bx-carousel",
    subItems: [
      {
        id: 131,
        label: 'Panel',  //Panel Eliminar Defectos
        //link: '/intervencionequiposfuga',
        link:"/eliminar-defectos-panel",
        parentId: 13,
      },
      {
          id: 14,
          label: 'MENUSIDEBAR.MENU_15',  //LOPC
          //link: '/intervencionequiposfuga',
          link:"/maintenance",
          parentId: 13,
      },
    ]
  },
  {
    id: 15,
    label: 'MENUSIDEBAR.MENU_13',  //Evaluar Estrategias (Gestionar Politicas)
    icon: "mdi mdi-strategy", //'bx-share-alt',
    subItems: [
      {
        id: 210,
        label: "MENUSIDEBAR.MENU_37",    // clasificar riesgo
        subItems: [

          {
              id: 211,
              label: 'Scorecard',  // Scorecard
              link: '/Scorecard',
              parentId: 210,
          },
          {
              id: 212,
              label: 'MENUSIDEBAR.MENU_34',  //Crear Analisis
              link: '/CrearAnalisis',
              parentId: 210,
          },
          // {
          //     id: 215,
          //     label: 'Ejecutar Analisis',  //Ejecutar Analisis
          //     link: '/EjecutarAnalisis',
          //     parentId: 210,
          // },
          {
            id: 216,
            label: 'MENUSIDEBAR.MENU_35',  //Aprobar Analisis
            link: '/AprobarAnalisis',
            parentId: 210,
            },
          {
            id: 217,
            label: 'MENUSIDEBAR.MENU_36',  //PromoverAnalisis
            link: '/PromoverAnalisis',
            parentId: 210,
            },
        ]
      },
        {
          id: 16,
          label: 'MENUSIDEBAR.MENU_16',   //Juntas Bridadas
          link: '/juntasbridadas',
          //link:"/maintenance",
          parentId: 15,
        },
        {
          id: 17,
          label: 'MENUSIDEBAR.MENU_21', //RBI
          parentId: 15,
          subItems: [
            {
                id: 18,
                label: 'MENUSIDEBAR.MENU_22',  // PanelRBI
                link: '/panelrbi',
                parentId:17,

            },
            {
              id: 19,
              label: 'MENUSIDEBAR.MENU_24',  //Ciclo de Vida del Analisis
              link: '/ciclovidaanalisis',
              parentId:17,
            },
            {
              id: 20,
              label: 'MENUSIDEBAR.MENU_25',  //Ciclo de Vida del Analisis
              link: '/politicasrbi',
              parentId:17,
            }
          ]
        },
    ]
  },

];



